body {
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.base-Popup-root {
  border: 1px solid #4d4d4d;
  border-radius: 0;
  box-shadow: 0 2px 6px #000000b2;
  z-index: 100 !important;
  top: 10px !important;
}

ruby > rt {
  white-space: nowrap;
}

:root {
  --main-font-family-db: "OT-PUDShinMGoPr6N-DeBold", "A-OTF UD Shin Maru Go Pr6N-DB", "Hiragino Sans", "Yu Gothic", "Shin Maru Go DemiBold", "Arial", "Microsoft YaHei", "Noto Sans JP", Helvetica;
  --main-font-family-b: "OT-PUDShinMGoPr6N-Bold", "A-OTF UD Shin Maru Go Pr6N-B", "Shin Maru Go Bold", "Hiragino Sans", "Yu Gothic", "Arial", "Microsoft YaHei", "Noto Sans JP", Helvetica;
  --main-font-family-m: "OT-PUDShinMGoPr6N-Medium", "A-OTF UD Shin Maru Go Pr6N-M", "Shin Maru Go Medium", "Hiragino Sans", "Yu Gothic", "Arial", "Microsoft YaHei", "Noto Sans JP", Helvetica;
  --main-font-family-r: "OT-PUDShinMGoPr6N-Regular", "A-OTF UD Shin Maru Go Pr6N-R", "Shin Maru Go Regular", "Hiragino Sans", "Yu Gothic", "Arial", "Microsoft YaHei", "Noto Sans JP", Helvetica;
  --main-font-family-rg: "OT-PUDShinMGoPr6N-Regular", "A-OTF UD Shin Maru Go Pr6N-Regular", "Shin Maru Go Regular", "Hiragino Sans", "Yu Gothic", "Arial", "Microsoft YaHei", "Noto Sans JP", Helvetica;
  --main-font-family-go-m: "A-OTF UD Shin Go Pro-M", "Hiragino Sans", "Yu Gothic", "Arial", "Microsoft YaHei", "Noto Sans JP", Helvetica;
  --tashikame: #e55c7e;
  --beta-blue: #0a93f5;
  --new-kanji: #00a5e7;
  --new-yomi-kanji: #39b54a;
  --letter-black: #333;
  --gray: #d9d9d9;
  --dark-gray: #828282;
  --dark-gray-2: #7c7c7c;
  --white: #fff;
  --letter-white: #ededed;
  --light-blue: #9dd4fb;
  --black: #000;
  --popup: #f7931e;
  --gray-disable: #ccc;
  --highlight: #f7931e33;
  --test-practice: #29b0ff;
  --beta-yellow: #fb0;
  --letter-blue: #0071bc;
  --disable: #ccc;
  --blue-line: #3fa9f5;
  --answer-blue: #05f;
  --popup-letter: #f15a24;
  --gold-red: red;
  --tomopla-color: #f97253;
  --tomopla-yellow: #faffbe;
  --tab-net: #ffbebe;
  --line-color: #4d4d4d;
  --gray2: #e6e6e6;
  --background-gray: #fafafa;
  --light-blue-2: #d2ecff;
  --light-blue-3: #f0f0f0;
  --theme-blue: #e6f0ff;
  --theme-blue-2: #f1f7ff;
  --theme-earth-2: #f2f0e7;
  --theme-dark: #404040;
  --dark-mode-line: #595959;
  --theme-earth-base: #fafaf5;
  --fff8f2: #fff8f2;
  --005795: #005795;
  --4db5ff: #4db5ff;
  --tomopla-color-mid: #f99a84;
  --tomopla-color-dot: #f9c2b6;
  --color-dictionary: #ade8a8;
  --color-dictionary-kanji: #fbe25f;
  --color-kanji-skill: #ffb3ac;
  --color-cbt: #ffb973;
  --color-calc-skill: #8fd7ff;
  --color-test: #ff7bba;
  --color-video: #ff8761;
  --color-romaji-skill: #ffb4d9;
  --color-help: #ececec;
  --color-dictionary-light: #dbf2aa;
  --color-test-light: #ffc7e2;
  --color-dictionary-kanji-light: #79b8f2;
  --color-cbt-light: #ffb6a6;
  --color-calc-skill-light: #c4eaff;
  --color-video-light: #f7dfeb;
  --color-romaji-skill-light: #daafe9;
  --color-unit1: #ffe19c;
  --color-unit2: #eeb113;
  --color-unit3: #a2d5e5;
  --color-unit4: #2ba0c6;
  --color-unit5: #355bbe;
  --color-unit6: #d2bbc0;
  --color-unit7: #c05b71;
  --tomopla-color-gray: #f97253cc;
  --tomopla-color-light: #fcece8;
  --light-box-shadow: #faffbe;
  --bg-table-summary-row: #fff8f2;
  --bg-table-summary-col: #ffbebe;
  --bg-table-pretest-row: #fff7d9;
  --bg-table-test-row: #fcece8;
  --bg-student-chart: #fcf2ef;
  --top-record-edge: #f95d39;
  --dark: #202124;
  --dark-gray: #323232;
  --dark-light: #4d4d4d;
  --blue: #0a93f5;
  --blue-gray: #0a93f5cc;
  --blue-light: #daeffe;
  --blue-back: #a1e9ee;
  --blue-edge: #0984db;
  --green: #00b259;
  --green-gray: #00b259cc;
  --green-light: #d9f3e6;
  --green-back: #ccecb4;
  --green-edge: #00b259;
  --pink: #ff8196;
  --pink-gray: #ff8196cc;
  --pink-light: #ffecef;
  --pink-back: #ffdac7;
  --pink-edge: #ff6982;
  --khaki: #a6976c;
  --khaki-gray: #a6976ccc;
  --khaki-light: #edeae2;
  --khaki-back: #e2e6ae;
  --khaki-edge: #a6976c;
}

/*# sourceMappingURL=index.d117d6b8.css.map */
