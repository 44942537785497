body {
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

.base-Popup-root {
  z-index: 100 !important;
  border: 1px solid rgba(77, 77, 77, 1);
  border-radius: 0px;
  box-shadow: 0px 2px 6px #000000b2;
  top: 10px !important;
}

ruby > rt {
  white-space: nowrap;
}

:root {
  --main-font-family-db: 'OT-PUDShinMGoPr6N-DeBold',
    'A-OTF UD Shin Maru Go Pr6N-DB', 'Hiragino Sans', 'Yu Gothic',
    'Shin Maru Go DemiBold', 'Arial', 'Microsoft YaHei', 'Noto Sans JP',
    Helvetica;
  --main-font-family-b: 'OT-PUDShinMGoPr6N-Bold', 'A-OTF UD Shin Maru Go Pr6N-B',
    'Shin Maru Go Bold', 'Hiragino Sans', 'Yu Gothic', 'Arial',
    'Microsoft YaHei', 'Noto Sans JP', Helvetica;
  --main-font-family-m: 'OT-PUDShinMGoPr6N-Medium',
    'A-OTF UD Shin Maru Go Pr6N-M', 'Shin Maru Go Medium', 'Hiragino Sans',
    'Yu Gothic', 'Arial', 'Microsoft YaHei', 'Noto Sans JP', Helvetica;
  --main-font-family-r: 'OT-PUDShinMGoPr6N-Regular',
    'A-OTF UD Shin Maru Go Pr6N-R', 'Shin Maru Go Regular', 'Hiragino Sans',
    'Yu Gothic', 'Arial', 'Microsoft YaHei', 'Noto Sans JP', Helvetica;
  --main-font-family-rg: 'OT-PUDShinMGoPr6N-Regular',
    'A-OTF UD Shin Maru Go Pr6N-Regular', 'Shin Maru Go Regular',
    'Hiragino Sans', 'Yu Gothic', 'Arial', 'Microsoft YaHei', 'Noto Sans JP',
    Helvetica;
  --main-font-family-go-m: 'A-OTF UD Shin Go Pro-M', 'Hiragino Sans',
    'Yu Gothic', 'Arial', 'Microsoft YaHei', 'Noto Sans JP', Helvetica;

  /*
  --text-font-family: "AveriaLibre-Regular", Helvetica;
  --text-font-size: 24px;
  --text-font-style: normal;
  --text-font-weight: 400;
  --text-letter-spacing: 0px;
  --text-line-height: normal;

  --r-large: 8px;
  --r-small: 4px;

  --: rgba(250, 255, 190, 1);
  --a-ys-r-nd: rgba(252, 236, 232, 1);
  --b-oou-i8: rgba(255, 255, 255, 1);
  --blue: rgba(0, 113, 188, 1);
  --cbt: rgba(255, 182, 166, 1);
  --cq-1tz8: rgba(249, 194, 182, 1);
  --czo-6p5: rgba(251, 226, 95, 1);
  --d-9d-9d-9: rgba(217, 217, 217, 1);
  --d-o-1kyd: rgba(230, 240, 255, 1);
  --disable: rgba(204, 204, 204, 1);
  --EL-njx-z: rgba(255, 180, 217, 1);
  --f-ioz7c: rgba(157, 212, 251, 1);
  --fff-8f-2: rgba(255, 248, 242, 1);
  --fg-LFNI: rgba(10, 147, 245, 1);
  --g4-skfj: rgba(255, 135, 97, 1);
  --GHD-pvu: rgba(0, 165, 231, 1);
  --GQZ-iun: rgba(0, 0, 0, 1);
  --i-nn-y77: rgba(241, 90, 36, 1);
  --js-14db: rgba(247, 147, 30, 0.2);
  --k-rrusd: rgba(255, 190, 190, 1);
  --l-sfed-d: rgba(249, 114, 83, 1);
  --lrm0cn: rgba(63, 169, 245, 1);
  --m-mo-3v-7: rgba(229, 92, 126, 1);
  --mk: rgba(236, 236, 236, 1);
  --qc-c-srg: rgba(217, 217, 217, 1);
  --qi0y-mt: rgba(41, 176, 255, 1);
  --ry-fd-s5: rgba(89, 89, 89, 1);
  --ul-kfi-c: rgba(255, 179, 172, 1);
  --uz2vmb: rgba(255, 187, 0, 1);
  --v-d-5r-js: rgba(77, 77, 77, 1);
  --vy-ik-KV: rgba(255, 0, 0, 1);
  --x: rgba(218, 175, 233, 1);
  --x-0-jn-OGD: rgba(10, 147, 245, 1);
  --x-005795: rgba(0, 87, 149, 1);
  --x-1: rgba(255, 225, 156, 1);
  --x-2: rgba(242, 240, 231, 1);
  --x-2y-vv-2a: rgba(217, 217, 217, 1);
  --x-3: rgba(162, 213, 229, 1);
  --x-4: rgba(43, 160, 198, 1);
  --x-4-tq-ssd: rgba(57, 181, 74, 1);
  --x-4db-5ff: rgba(77, 181, 255, 1);
  --x-5: rgba(53, 91, 190, 1);
  --x-6: rgba(210, 187, 192, 1);
  --x-6b7n-la: rgba(0, 85, 255, 1);
  --x-6gi-HHQ: rgba(64, 64, 64, 1);
  --x-6oz-HJH: rgba(255, 123, 186, 1);
  --x-7: rgba(192, 91, 113, 1);
  --x-7x-JOCP: rgba(250, 250, 250, 1);
  --x-8w-fkoj: rgba(51, 51, 51, 1);
  --y-c-2k-ne: rgba(230, 240, 255, 1);
  --YKS-5x-1: rgba(173, 232, 168, 1);
  --z-9vx-rd: rgba(247, 147, 30, 1);
  --zx-3-7c-w: rgba(143, 215, 255, 1);

  --gray: rgba(77, 77, 77, 1);
  --a-mr-dh4: rgba(255, 187, 0, 1);
  --bbf-ah-6: rgba(0, 165, 231, 1);
  --blue: rgba(0, 113, 188, 1);
  --cbt: rgba(255, 182, 166, 1);
  --d-9d-9d-9: rgba(217, 217, 217, 1);
  --disable: rgba(204, 204, 204, 1);
  --e-2u-0qe: rgba(173, 232, 168, 1);
  --e-3w-639: rgba(63, 169, 245, 1);
  --eusk5f: rgba(64, 64, 64, 1);
  --fcyhvz: rgba(255, 135, 97, 1);
  --fff-8f-2: rgba(255, 248, 242, 1);
  --fr-fwl8: rgba(255, 0, 0, 1);
  --g-jme-r5: rgba(41, 176, 255, 1);
  --hnb-OST: rgba(249, 114, 83, 1);
  --hx-i4-us: rgba(230, 240, 255, 1);
  --k-sbly-p: rgba(255, 180, 217, 1);
  --legz-26: rgba(255, 179, 172, 1);
  --m-mzb-DH: rgba(229, 92, 126, 1);
  --m-sap-4v: rgba(143, 215, 255, 1);
  --mk: rgba(236, 236, 236, 1);
  --mvdbb-z: rgba(51, 51, 51, 1);
  --n-8-4h-UZ: rgba(250, 250, 250, 1);
  --n-gvc-5w: rgba(0, 85, 255, 1);
  --p-4-iq-58: rgba(252, 236, 232, 1);
  --p-7-yuiw: rgba(241, 90, 36, 1);
  --p6-twxf: rgba(57, 181, 74, 1);
  --pb-uf-3t: rgba(249, 194, 182, 1);
  --spy7-5q: rgba(255, 255, 255, 1);
  --vx-zk-sw: rgba(247, 147, 30, 1);
  --vyr-7sd: rgba(247, 147, 30, 0.2);
  --w-zszq-v: rgba(10, 147, 245, 1);
  --w682-rx: rgba(255, 123, 186, 1);
  --wzx-kbz: rgba(255, 190, 190, 1);
  --x: rgba(218, 175, 233, 1);
  --x-005795: rgba(0, 87, 149, 1);
  --x-07rfli: rgba(157, 212, 251, 1);
  --x-1: rgba(255, 225, 156, 1);
  --x-2: rgba(242, 240, 231, 1);
  --x-2-6w-cvm: rgba(0, 0, 0, 1);
  --x-26fz-vv: rgba(251, 226, 95, 1);
  --x-3: rgba(162, 213, 229, 1);
  --x-4: rgba(43, 160, 198, 1);
  --x-4db-5ff: rgba(77, 181, 255, 1);
  --x-5: rgba(53, 91, 190, 1);
  --x-6: rgba(210, 187, 192, 1);
  --x-7: rgba(192, 91, 113, 1);
  --x-7p-2-st-h: rgba(89, 89, 89, 1);
  --x-7tc-luy: rgba(217, 217, 217, 1);
  --x-85: rgba(217, 217, 217, 1);
  --xb-fn0c: rgba(77, 77, 77, 1);
*/
  --tashikame: rgba(229, 92, 126, 1);
  --beta-blue: rgba(10, 147, 245, 1);
  --new-kanji: rgba(0, 165, 231, 1);
  --new-yomi-kanji: rgba(57, 181, 74, 1);
  --letter-black: rgba(51, 51, 51, 1);
  --gray: rgba(217, 217, 217, 1);
  --dark-gray: rgba(130, 130, 130, 1);
  --dark-gray-2: rgba(124, 124, 124, 1);
  --white: rgba(255, 255, 255, 1);
  --letter-white: rgba(237, 237, 237, 1);
  --light-blue: rgba(157, 212, 251, 1);
  --black: rgba(0, 0, 0, 1);
  --popup: rgba(247, 147, 30, 1);
  --gray-disable: rgba(204, 204, 204, 1);
  --highlight: rgba(247, 147, 30, 0.2);
  --test-practice: rgba(41, 176, 255, 1);
  --beta-yellow: rgba(255, 187, 0, 1);
  --letter-blue: rgba(0, 113, 188, 1);
  --disable: rgba(204, 204, 204, 1);
  --blue-line: rgba(63, 169, 245, 1);
  --answer-blue: rgba(0, 85, 255, 1);
  --popup-letter: rgba(241, 90, 36, 1);
  --gold-red: rgba(255, 0, 0, 1);
  --tomopla-color: rgba(249, 114, 83, 1);
  --tomopla-yellow: rgba(250, 255, 190, 1);
  --tab-net: rgba(255, 190, 190, 1);
  --line-color: rgba(77, 77, 77, 1);
  --gray2: rgba(230, 230, 230, 1);
  --background-gray: rgba(250, 250, 250, 1);
  --light-blue-2: rgba(210, 236, 255, 1);
  --light-blue-3: rgba(240, 240, 240, 1);
  --theme-blue: rgba(230, 240, 255, 1);
  --theme-blue-2: rgba(241, 247, 255, 1);
  --theme-earth-2: rgba(242, 240, 231, 1);
  --theme-dark: rgba(64, 64, 64, 1);
  --dark-mode-line: rgba(89, 89, 89, 1);
  --theme-earth-base: rgba(250, 250, 245, 1);
  --fff8f2: rgba(255, 248, 242, 1);
  --005795: rgba(0, 87, 149, 1);
  --4db5ff: rgba(77, 181, 255, 1);
  --tomopla-color-mid: rgba(249, 154, 132, 1);
  --tomopla-color-dot: rgba(249, 194, 182, 1);
  --color-dictionary: rgba(173, 232, 168, 1);
  --color-dictionary-kanji: rgba(251, 226, 95, 1);
  --color-kanji-skill: rgba(255, 179, 172, 1);
  --color-cbt: rgba(255, 185, 115, 1);
  --color-calc-skill: rgba(143, 215, 255, 1);
  --color-test: rgba(255, 123, 186, 1);
  --color-video: rgba(255, 135, 97, 1);
  --color-romaji-skill: rgba(255, 180, 217, 1);
  --color-help: rgba(236, 236, 236, 1);
  --color-dictionary-light: rgba(219, 242, 170, 1);
  --color-test-light: rgba(255, 199, 226, 1);
  --color-dictionary-kanji-light: rgba(121, 184, 242, 1);
  --color-cbt-light: rgba(255, 182, 166, 1);
  --color-calc-skill-light: rgba(196, 234, 255, 1);
  --color-video-light: rgba(247, 223, 235, 1);
  --color-romaji-skill-light: rgba(218, 175, 233, 1);
  --color-unit1: rgba(255, 225, 156, 1);
  --color-unit2: rgba(238, 177, 19, 1);
  --color-unit3: rgba(162, 213, 229, 1);
  --color-unit4: rgba(43, 160, 198, 1);
  --color-unit5: rgba(53, 91, 190, 1);
  --color-unit6: rgba(210, 187, 192, 1);
  --color-unit7: rgba(192, 91, 113, 1);
  --tomopla-color-gray: rgba(249, 114, 83, 0.8);
  --tomopla-color-light: rgba(252, 236, 232, 1);
  --light-box-shadow: rgba(250, 255, 190, 1);
  --bg-table-summary-row: rgba(255, 248, 242, 1);
  --bg-table-summary-col: rgba(255, 190, 190, 1);
  --bg-table-pretest-row: rgba(255, 247, 217, 1);
  --bg-table-test-row: rgba(252, 236, 232, 1);
  --bg-student-chart: rgba(252, 242, 239, 1);
  --top-record-edge: rgba(249, 93, 57, 1);

  --dark: rgba(32, 33, 36, 1);
  --dark-gray: rgba(50, 50, 50, 1);
  --dark-light: rgba(77, 77, 77, 1);

  --blue: rgba(10, 147, 245, 1);
  --blue-gray: rgba(10, 147, 245, 0.8);
  --blue-light: rgba(218, 239, 254, 1);
  --blue-back: rgba(161, 233, 238, 1);
  --blue-edge: rgba(9, 132, 219, 1);

  --green: rgba(0, 178, 89, 1);
  --green-gray: rgba(0, 178, 89, 0.8);
  --green-light: rgba(217, 243, 230, 1);
  --green-back: rgba(204, 236, 180, 1);
  --green-edge: rgba(0, 178, 89, 1);

  --pink: rgba(255, 129, 150, 1);
  --pink-gray: rgba(255, 129, 150, 0.8);
  --pink-light: rgba(255, 236, 239, 1);
  --pink-back: rgba(255, 218, 199, 1);
  --pink-edge: rgba(255, 105, 130, 1);

  --khaki: rgba(166, 151, 108, 1);
  --khaki-gray: rgba(166, 151, 108, 0.8);
  --khaki-light: rgba(237, 234, 226, 1);
  --khaki-back: rgba(226, 230, 174, 1);
  --khaki-edge: rgba(166, 151, 108, 1);
}
